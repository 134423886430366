export default {
  errorCodes:{
    'CARD_DENIED' : 'Your payment method was declined. Please add or select an alternative payment method.',
    'INVALID_CARD_ERROR': 'The payment method is invalid. Please add a new payment method.',
    'UNSUPPORTED_CARD_ERROR': 'Your payment method is not supported. Only VISA, MasterCard, America Express, Discover, JSC, and UnionPay are accepted.',
    'VALIDATION_ERROR': 'Error validating request, please verify data and resubmit.',
    'DELAYED_TRANSACTION_ERROR': 'Error capturing pre-authorized transaction.',
    'RATE_LIMIT_ERROR': 'Intermittent error calling square, please resubmit.',
    'RETRYABLE_ERROR': 'Intermittent error calling square, please resubmit.',
    'UNKNOWN_SQUARE_ERROR': 'Unable to process request, please try again, if this continues contact support.',
    'UNKNOWN_ERROR': 'Unable to process request, please try again, if this continues contact support.',
    'REFUND_UNDEFINED_SPLIT': 'Unable to process refund, please setup the refund plan and try again.',
    'REFUND_FUNDS_UNAVAILABLE': 'Unable to process refund, related transactions have insufficient funds to process request.',
    'NOT_FOUND': 'Unable to find transaction to process.',
    'ERROR_UPDATING_AX': 'Error updating AX with transaction information. Please contact support.',
    'ACCOUNT_NOT_FOUND': 'Unable to find account to process request.',
  }
}